
import {
    defineComponent, onBeforeMount, reactive, computed,
} from 'vue';
import Screen from '@/components/layout/Screen.vue';
import UserRoleService from '@/services/RoleService';
import Role from '@/domain/Role';
import { TableDefinition } from '@/types';
import router from '@/router';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import EntityType from '@/domain/enums/EntityTypes';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import Permissions from '@/services/permissions/Permissions';

type State = {
    roles: Array<Role>;
    loading: boolean;
};

export default defineComponent({
    name: 'user-roles-manager',
    components: {
        Screen,
    },

    setup() {
        const state = reactive<State>({
            roles: [],
            loading: true,
        });

        const roleService = new UserRoleService();

        async function fetchRoles() {
            const response = await roleService.getAllRoles();
            if (response.success) {
                state.roles = response.roles;
            }
            state.loading = false;
        }

        onBeforeMount(async () => {
            await fetchRoles();
        });

        const table = computed(
            (): TableDefinition<Role> => ({
                items: state.roles,
                key: 'rolesList',
                name: getTitleCaseTranslation('core.domain.roles'),
                columnDefinition: [
                    {
                        key: 'name',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.roleName'),
                    },
                    {
                        key: 'description',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.roleDescription'),
                    },
                ],
            }),
        );

        async function openAdd() {
            await router.push({
                name: MasterDataRouteTypes.ROLES.ADD,
            });
        }

        async function openEdit(role: Role) {
            await router.push({
                name: MasterDataRouteTypes.ROLES.EDIT,
                params: { roleId: role.id!.toString() },
            });
        }

        async function openRolesHistory(role: Role) {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.ROLE, entityId: role.id },
            });
        }

        return {
            state,
            table,
            openAdd,
            openEdit,
            openRolesHistory,
            getTitleCaseTranslation,
            Permissions,
        };
    },
});
